import React from 'react';
import { Button, Input, Table } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@constants/common';
import { fetchExpiryReminderHistory } from '../expiryReminderHistoryAPI';

import "./style.less"

dayjs.extend(utc);
dayjs.extend(timezone);

interface IExpiryReminder {
  pk: number
  corporate: string,
  credit_id: string,
  reference_id: string,
  created_on: string | null,
  promotion: string,
  company_name: string,
  is_reminder_sent: boolean,
  status: string
}


const ExpiryReminderHistoryTable: React.FC = () => {
  const [data, setData] = React.useState<IExpiryReminder[]>([]);
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [isSearching, setSearching] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const navigate = useNavigate();

  React.useEffect(() => {
    getExpiryRemindersList();
  }, [])


  const getExpiryRemindersList = async (page: number = 1, searchVal?: string,) => {
    setFetching(true);
    const res = await fetchExpiryReminderHistory({
      page,
      ...(searchVal && { credit_id: searchVal })
    })

    if (res.ok) {
      setData(res.data.data);
      setTotalCount(res.data.total_count);
      setCurrentPage(res.data.current_page);
    }
    setFetching(false);
  }

  const handlePagination = (page: number) => {
    getExpiryRemindersList(page, searchValue);
  }

  const handleSearch = async (val: string) => {
    if (val) {
      setSearching(true);
      await getExpiryRemindersList(1, val);
      setSearching(false);
    }
  }

  const handleSearchFieldChange = async (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);

    if (!e.currentTarget.value) {
      getExpiryRemindersList();
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_on',
      render: (val: string) => (val ? `${dayjs(val).tz('Asia/Dubai').format("DD MMM YYYY HH:mm")} UAE` : "-")
    },
    {
      title: 'Credit ID',
      ellipsis: true,
      dataIndex: 'credit_id',

      render: (val: string) => (<span className='expiry-table--id-cell'>{val ? val : "-"}</span>)
    },
    {
      title: 'Promotion Name',
      ellipsis: true,
      dataIndex: 'promotion',
    },
    {
      title: 'Company Name',
      ellipsis: true,
      dataIndex: 'company_name',
    },
    {
      title: 'Status',
      dataIndex: 'is_reminder_sent',
      align: "right" as AlignType,
      render: (val: string) => (<span className={`expiry-table--table-cell-status${val ? " success" : " failed"}`}>{val ? "Success" : "Failed"}</span>)
    },

  ];

  return (
    <div className="expiry-table">

      <div className="expiry-table--filters">
        <div className='expiry-table--filters-search'>
          <label htmlFor="search-by-id">Search by:</label>
          <Input.Search
            id="search-by-id"
            size='large'
            placeholder="Credit ID"
            style={{ width: 250 }}
            maxLength={50}
            loading={isSearching}
            onSearch={handleSearch}
            onChange={handleSearchFieldChange}
          />
        </div>
        <div className='fulfilment-table--filters-date'>
          <Button size='large' type='primary' onClick={() => navigate(PAGE_URLS.EXPIRY_REMINDER)}>Send a Reminder</Button>
        </div>

      </div>

      <div className="expiry-table--table">
        <Table
          rowKey={"pk"}
          columns={columns}
          dataSource={data}
          loading={isFetching}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 12,
            total: totalCount,
            current: currentPage,
            showSizeChanger: false,
            showTotal: (total, range) => <span className='table-pagination-total'>{range[0]}-{range[1]} of {total} items</span>,
            onChange: handlePagination
          }}
        />
      </div>
    </div>
  )
}
export default ExpiryReminderHistoryTable;
