import React from 'react'
import { Avatar, Popover, Divider, Button, message, Skeleton } from 'antd';
import { DownOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { setIsLoggedIn } from '@features/common/authSlice';
import { logoutUser } from '@features/common/authAPI';
import { fetchProfile } from '@features/common/commonAPI';
import { resetCommonSlice, setprofileData } from '../commonSlice';
import './style.less'
import { PAGE_URLS } from '@constants/common';


const UserPopoverMenu: React.FC = () => {
    const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
    const [isFetching, setFetching] = React.useState<boolean>(false);

    const { full_name, email, corporate } = useAppSelector(
        (state) => state.common.profileData
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        handleGetProfile();
    }, [])

    const handleGetProfile = async () => {
        setFetching(true);
        const res = await fetchProfile();

        if (res.ok) {
            const popoverData = {
                full_name: res?.data?.data?.full_name,
                first_name: res?.data?.data?.first_name,
                last_name: res?.data?.data?.last_name,
                email: res?.data?.data?.email,
                corporate: res?.data?.data?.corporate,
            };
            dispatch(setprofileData(popoverData));
        } else {
            message.error(res.data?.message || "Something went wrong.");
        }
        setFetching(false);
    }

    const handleLogout = async () => {
        setSubmitting(true);
        const res = await logoutUser();
        if (res.ok) {
            message.success(res.data.message);
            dispatch(resetCommonSlice());
            navigate(PAGE_URLS.LOGIN, { replace: true });
            dispatch(setIsLoggedIn(false))
        } else {
            message.error(res.data.message || "Something went wrong.");
        }
        setSubmitting(false);
    }

    const popOverContent = () => (
        <div className='user-popover-menu--content'>
            <p className='user-popover-menu--powered-by'>
                Powered by <b>YouGotaGift.com</b>
            </p>
            <div className='user-popover-menu--user-details'>
                <h4 className='user-popover-menu--user-details-heading'>User Details</h4>
                <div className='user-popover-menu--user-details-info'>
                    {!isFetching ?
                        <>
                            <div className='user-popover-menu--avatar-container'>
                                <Avatar size={50}>
                                    {full_name.charAt(0)}
                                </Avatar>
                                <CheckCircleFilled />
                            </div>
                            <div className='user-popover-menu--user-details-info-profile'>
                                <p>
                                    {full_name}
                                    <br />
                                    {email}
                                    {corporate && (
                                        <b>
                                            <br />
                                            Corporate: {corporate}
                                        </b>
                                    )
                                    }

                                </p>
                            </div>
                        </>
                        :
                        <Skeleton className='user-popover-menu--skeleton' avatar={{ size: 50 }} paragraph={{ rows: 1 }} />
                    }

                </div>
            </div>
            <Divider />
            <div className='user-popover-menu--footer'>
                <Button onClick={handleLogout} loading={isSubmitting} className='secondary-btn' size='large'>Sign Out</Button>
            </div>
        </div>
    )
    return (
        <Popover
            className='user-popover-menu'
            overlayClassName='user-popover-menu--overlay'
            placement="bottomLeft"
            title={""}
            content={popOverContent}
            trigger="click"
        >
            <div className='user-popover-menu--avatar'>
                {!isFetching ?
                    <Avatar >
                        {full_name.charAt(0)}
                    </Avatar>
                    :
                    <Skeleton.Avatar active />
                }

                <div className='user-popover-menu--avatar-bottom'>
                    <span> Me</span>
                    <DownOutlined />
                </div>
            </div>
        </Popover>

    )
}

export default UserPopoverMenu;
