import React from 'react'


interface ILogoProps {
    height?: number
}

// Using svg inside jsx for faster load
const Logo: React.FC<ILogoProps> = ({height=50}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={74.778} height={height} viewBox="0 0 74.778 50">
            <g transform="translate(-30.236 -28.313)">
                <path data-name="Path 60223" d="M225.612 63.843a2.912 2.912 0 0 0-5.823 0v.078c.008 2.246.317 5.806-.844 7.719a5.846 5.846 0 0 1-10.375 0c-1.161-1.914-.852-5.473-.844-7.719v-.078a2.912 2.912 0 0 0-5.823 0v4.846c-.084 7.211 5.861 11.34 11.849 11.413 5.988-.073 11.933-4.2 11.849-11.413v-4.814a.046.046 0 0 0 .011-.032z" transform="translate(-120.598 -23.106)" style={{ fill: "#c028b9" }} />
                <path data-name="Path 60224" d="M38.114 54.445V43.387l-7.346-10.345a2.969 2.969 0 0 1-.532-1.746 2.8 2.8 0 0 1 .788-2 2.648 2.648 0 0 1 2-.826 2.731 2.731 0 0 1 2.411 1.158l5.6 8.529 5.6-8.529a2.7 2.7 0 0 1 2.411-1.158 2.649 2.649 0 0 1 2 .826 2.8 2.8 0 0 1 .788 2 2.971 2.971 0 0 1-.531 1.746l-7.362 10.345v11.058a2.907 2.907 0 0 1-.731 2.012 2.767 2.767 0 0 1-2.174.816 2.791 2.791 0 0 1-2.192-.816 2.906 2.906 0 0 1-.73-2.012z" transform="translate(0 -.108)" style={{ fill: "#c028b9" }} />
                <path data-name="Path 60225" d="M116.351 57.006a12.8 12.8 0 0 1-11.665-7.062 15.542 15.542 0 0 1-1.7-7.251 16.133 16.133 0 0 1 .968-5.638 13.723 13.723 0 0 1 2.762-4.575 12.51 12.51 0 0 1 4.243-3.028 13.3 13.3 0 0 1 5.391-1.073 12.954 12.954 0 0 1 5.362 1.111 12.786 12.786 0 0 1 4.262 3.065 14.076 14.076 0 0 1 2.771 4.575 15.648 15.648 0 0 1 .987 5.562 16.239 16.239 0 0 1-.949 5.59 13.548 13.548 0 0 1-2.762 4.584 12.638 12.638 0 0 1-4.281 3.056 13.276 13.276 0 0 1-5.389 1.084zm.009-5.372a6.933 6.933 0 0 0 3.174-.7 6.479 6.479 0 0 0 2.319-1.965 8.715 8.715 0 0 0 1.378-2.866 12.645 12.645 0 0 0 .447-3.407 11.607 11.607 0 0 0-.837-4.461 7.48 7.48 0 0 0-2.5-3.255 7.088 7.088 0 0 0-7.964 0 7.485 7.485 0 0 0-2.5 3.255 11.607 11.607 0 0 0-.836 4.461 10.447 10.447 0 0 0 1.891 6.378 6.336 6.336 0 0 0 5.428 2.56z" transform="translate(-51.106 -.047)" style={{ fill: "#c028b9" }} />
                <circle data-name="Ellipse 544" cx="3.222" cy="3.222" r="3.222" transform="translate(82.943 28.313)" style={{ opacity: .5, fill: "#c028b9" }} />
                <circle data-name="Ellipse 545" cx="3.222" cy="3.222" r="3.222" transform="translate(96.878 28.313)" style={{ opacity: .5, fill: "#c028b9" }} />
                <g data-name="Group 45963">
                    <path data-name="Path 60226" d="M48.215 157.053a6.047 6.047 0 0 1-2.493-.5 3.891 3.891 0 0 1-1.781-1.615 5.767 5.767 0 0 1-.665-2.969v-7.338a5.767 5.767 0 0 1 .665-2.969 3.906 3.906 0 0 1 1.769-1.615 5.966 5.966 0 0 1 2.482-.5 6.052 6.052 0 0 1 2.636.534 4.212 4.212 0 0 1 1.734 1.461 3.765 3.765 0 0 1 .617 2.137.749.749 0 0 1-.214.605.862.862 0 0 1-.57.178 1.025 1.025 0 0 1-.558-.143.546.546 0 0 1-.249-.451 3.56 3.56 0 0 0-.5-1.544 2.652 2.652 0 0 0-1.116-.985 4.023 4.023 0 0 0-1.757-.339 3.312 3.312 0 0 0-2.47.891 3.766 3.766 0 0 0-.879 2.743v7.338a3.768 3.768 0 0 0 .879 2.743 3.313 3.313 0 0 0 2.47.89 3.356 3.356 0 0 0 2.482-.89 3.738 3.738 0 0 0 .89-2.743v-2.826h-3.158a.52.52 0 0 1-.439-.2.738.738 0 0 1-.154-.463.79.79 0 0 1 .154-.475.509.509 0 0 1 .439-.214h4.156a.534.534 0 0 1 .439.19.693.693 0 0 1 .154.451v3.539a5.766 5.766 0 0 1-.665 2.969 3.9 3.9 0 0 1-1.781 1.615 6.113 6.113 0 0 1-2.517.495z" transform="translate(-9.16 -78.763)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60227" d="M89.135 171.207a4.232 4.232 0 0 1-2.244-.63 4.8 4.8 0 0 1-1.651-1.662 4.252 4.252 0 0 1-.617-2.22v-2.565a4.255 4.255 0 0 1 .605-2.209 4.645 4.645 0 0 1 1.639-1.638 4.467 4.467 0 0 1 4.512-.012 4.678 4.678 0 0 1 1.651 1.615 4.217 4.217 0 0 1 .617 2.244v2.565a4.275 4.275 0 0 1-.617 2.208 4.771 4.771 0 0 1-1.651 1.674 4.233 4.233 0 0 1-2.244.63zm0-1.425a2.654 2.654 0 0 0 1.5-.439 3.32 3.32 0 0 0 1.068-1.152 2.99 2.99 0 0 0 .4-1.5v-2.561a2.931 2.931 0 0 0-.392-1.472 3.245 3.245 0 0 0-1.069-1.128 2.713 2.713 0 0 0-1.508-.439 2.654 2.654 0 0 0-1.5.439 3.389 3.389 0 0 0-1.069 1.128 2.863 2.863 0 0 0-.4 1.472v2.565a3.061 3.061 0 0 0 .392 1.5 3.139 3.139 0 0 0 1.081 1.152 2.727 2.727 0 0 0 1.497.435z" transform="translate(-38.206 -92.894)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60228" d="M127.112 158.76a4.2 4.2 0 0 1-2.8-.831 3.313 3.313 0 0 1-.974-2.66v-12.491a.489.489 0 0 1 .249-.439.961.961 0 0 1 .511-.154.982.982 0 0 1 .546.154.5.5 0 0 1 .238.439v4.75h3.087a.451.451 0 0 1 .38.19.784.784 0 0 1 0 .9.45.45 0 0 1-.38.19h-3.089v6.46a2.162 2.162 0 0 0 .5 1.627 2.542 2.542 0 0 0 1.734.463h.665a.646.646 0 0 1 .511.2.711.711 0 0 1 .178.487.756.756 0 0 1-.178.5.628.628 0 0 1-.511.214z" transform="translate(-65.401 -80.613)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60229" d="M147.8 171.207a3.487 3.487 0 0 1-2.292-.772 2.882 2.882 0 0 1-.938-2.363 2.716 2.716 0 0 1 .7-2.007 3.845 3.845 0 0 1 1.948-.973 13.582 13.582 0 0 1 2.885-.273h1.212v-.546a4.442 4.442 0 0 0-.273-1.532 2.775 2.775 0 0 0-.879-1.235 2.565 2.565 0 0 0-1.651-.487 4.136 4.136 0 0 0-1.282.166 3.749 3.749 0 0 0-.784.344.963.963 0 0 1-.475.178.531.531 0 0 1-.463-.285 1 1 0 0 1-.178-.522q0-.332.5-.606a5.053 5.053 0 0 1 1.247-.451 6.394 6.394 0 0 1 1.484-.178 4.356 4.356 0 0 1 2.529.653 3.7 3.7 0 0 1 1.354 1.7 6 6 0 0 1 .416 2.233v6.174a.563.563 0 0 1-.2.439.815.815 0 0 1-.558.178.8.8 0 0 1-.511-.178.54.54 0 0 1-.226-.439v-1.045a6.275 6.275 0 0 1-1.472 1.271 3.815 3.815 0 0 1-2.093.556zm.38-1.306a2.989 2.989 0 0 0 1.5-.4 3.987 3.987 0 0 0 1.175-.986 1.807 1.807 0 0 0 .464-1.1v-1.52h-1a15.13 15.13 0 0 0-2.031.131 3.5 3.5 0 0 0-1.568.558 1.541 1.541 0 0 0-.605 1.353 1.784 1.784 0 0 0 .605 1.5 2.3 2.3 0 0 0 1.459.463z" transform="translate(-80.317 -92.894)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60230" d="M186.706 157.053a6.046 6.046 0 0 1-2.493-.5 3.891 3.891 0 0 1-1.781-1.615 5.764 5.764 0 0 1-.665-2.969v-7.338a5.764 5.764 0 0 1 .665-2.969 3.906 3.906 0 0 1 1.769-1.615 5.965 5.965 0 0 1 2.482-.5 6.052 6.052 0 0 1 2.636.534 4.214 4.214 0 0 1 1.734 1.461 3.765 3.765 0 0 1 .617 2.137.748.748 0 0 1-.214.605.861.861 0 0 1-.57.178 1.025 1.025 0 0 1-.558-.143.545.545 0 0 1-.249-.451 3.557 3.557 0 0 0-.5-1.544 2.65 2.65 0 0 0-1.116-.985 4.024 4.024 0 0 0-1.757-.345 3.312 3.312 0 0 0-2.47.891 3.766 3.766 0 0 0-.879 2.743v7.338a3.768 3.768 0 0 0 .879 2.743 3.313 3.313 0 0 0 2.47.89 3.355 3.355 0 0 0 2.482-.89 3.737 3.737 0 0 0 .891-2.743v-2.826h-3.159a.52.52 0 0 1-.439-.2.738.738 0 0 1-.154-.463.79.79 0 0 1 .154-.475.509.509 0 0 1 .439-.214h4.156a.535.535 0 0 1 .439.19.7.7 0 0 1 .154.451v3.539a5.77 5.77 0 0 1-.665 2.969 3.894 3.894 0 0 1-1.781 1.615 6.11 6.11 0 0 1-2.517.501z" transform="translate(-106.448 -78.763)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60231" d="M224.661 144.315a1.065 1.065 0 0 1-.748-.3.924.924 0 0 1-.321-.7.888.888 0 0 1 .321-.688 1.089 1.089 0 0 1 .748-.285 1.047 1.047 0 0 1 .736.285.9.9 0 0 1 .309.688.94.94 0 0 1-.309.7 1.024 1.024 0 0 1-.736.3zm-.024 14.558a.8.8 0 0 1-.534-.19.553.553 0 0 1-.226-.428v-10.021a.5.5 0 0 1 .226-.439.923.923 0 0 1 .534-.154.966.966 0 0 1 .558.154.5.5 0 0 1 .226.439v10.022a.552.552 0 0 1-.226.428.837.837 0 0 1-.558.189z" transform="translate(-135.83 -80.726)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60232" d="M240.452 155.116a.824.824 0 0 1-.523-.166.517.517 0 0 1-.214-.427v-13.157a4.237 4.237 0 0 1 .582-2.2 4.54 4.54 0 0 1 1.544-1.579 3.929 3.929 0 0 1 2.1-.594h.594a.593.593 0 0 1 .5.214.79.79 0 0 1 .166.5.756.756 0 0 1-.166.475.593.593 0 0 1-.5.214h-.4a2.722 2.722 0 0 0-1.46.4 2.931 2.931 0 0 0-1.021 1.045 3 3 0 0 0-.392 1.425v2.612h3.4a.475.475 0 0 1 .4.19.731.731 0 0 1 .142.451.745.745 0 0 1-.142.439.464.464 0 0 1-.4.2h-3.4v9.357a.5.5 0 0 1-.237.427.967.967 0 0 1-.573.174z" transform="translate(-147.157 -76.969)" style={{ fill: "#c028b9" }} />
                    <path data-name="Path 60233" d="M267.6 158.76a4.2 4.2 0 0 1-2.8-.831 3.314 3.314 0 0 1-.974-2.66v-12.491a.489.489 0 0 1 .25-.439.961.961 0 0 1 .511-.154.983.983 0 0 1 .546.154.5.5 0 0 1 .237.439v4.75h3.087a.451.451 0 0 1 .38.19.784.784 0 0 1 0 .9.45.45 0 0 1-.38.19h-3.087v6.46a2.162 2.162 0 0 0 .5 1.627 2.543 2.543 0 0 0 1.734.463h.665a.646.646 0 0 1 .51.2.711.711 0 0 1 .178.487.756.756 0 0 1-.178.5.628.628 0 0 1-.51.214z" transform="translate(-164.09 -80.613)" style={{ fill: "#c028b9" }} />
                </g>
            </g>
        </svg>
    )
}

export default Logo;