
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from '@redux/store';
import Routes from './routes/Router';
import PageLoader from '@features/common/pageLoader/PageLoader';
import ResponsiveWarning from '@features/common/responsiveWarning/ResponsiveWarning';
import isUnsupporteddevice from '@utils/isUnsupporteddevice';

import '@styles/custom.less'

let persistor = persistStore(store);

export default function App() {
    const isNotSupported = isUnsupporteddevice();

    return (
        <Provider store={store}>
            <PersistGate loading={<PageLoader />} persistor={persistor}>
                {isNotSupported && <ResponsiveWarning />}
                <Routes />
            </PersistGate>
        </Provider>
    )
}

