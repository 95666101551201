import React from 'react';
import PageHeader from '@features/common/pageHeader/PageHeader';
import { Card } from 'antd';
import CreateReminderForm from '@features/expiryReminder/createReminderForm/CreateReminderForm';

import './style.less';


const ExpiryReminder = () => {
    return (
        <div className="app-container dashboard">
            <PageHeader heading="Expiry Reminder" />
            <div className="expiry-reminder">
                <Card className="expiry-reminder--container">
                    <CreateReminderForm />
                </Card>
            </div>
        </div>
    );
}

export default ExpiryReminder;