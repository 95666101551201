import YgagLogo from 'images/YgagLogo';
import './style.less'

export default function PageLoader() {
    return (
        <div className="init-loader">
            <div className="img-container">
                <YgagLogo />
            </div>
            <div className="page-loader"></div>
        </div>
    );
}