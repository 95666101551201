import React from 'react'
import { message } from 'antd';
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "redux/store";
import Cookies from 'js-cookie';
import { PAGE_URLS } from "constants/common";
import { fetchRefreshToken, fetchPermission } from 'features/common/authAPI';
import { setIsLoggedIn, setJwtToken } from 'features/common/authSlice';
import PageLoader from 'features/common/pageLoader/PageLoader';

const PrivateRoute = () => {
    const { isLoggedIn, jwtToken } = useAppSelector(state => state.auth);
    const { currentCorporate } = useAppSelector(state => state.common);

    const [isReadyToMount, setIsReadyToMount] = React.useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isCorporateSelectionPage = location.pathname === PAGE_URLS.SELECT_CORPORATE

    React.useEffect(() => {
        setIsReadyToMount(false);
        const isAccessTokenValid = Cookies.get('access_token_expiry');
        if (!isAccessTokenValid || !jwtToken || !currentCorporate.name) {
            getRefreshToken();
        } else {
            setIsReadyToMount(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const getRefreshToken = async () => {
        const res = await fetchRefreshToken();

        if (!!res) {
            dispatch(setJwtToken(res.access));
            const permissionsRes = await fetchPermission();

            if (permissionsRes.ok) {
                if (!permissionsRes.data.data.is_corporate_selected && isLoggedIn && !isCorporateSelectionPage) {
                    message.warning("Please choose a corporate to proceed.")
                    navigate(PAGE_URLS.SELECT_CORPORATE)
                    setIsReadyToMount(true);

                }
            }

        } else {
            message.warning("Session Expired.");
            dispatch(setJwtToken(""));
            dispatch(setIsLoggedIn(false));
        }

        setIsReadyToMount(true);
    }

    if (!isReadyToMount) {
        return (<PageLoader />)
    }

    return isLoggedIn ? <Outlet /> : <Navigate to={PAGE_URLS.LOGIN} />;
}

export default PrivateRoute;
