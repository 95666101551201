enum ERROR_MESSAGES {
    EMAIL_REQUIRED = "Email is required!",
    VALID_EMAIL_REQUIRED = "Email is not valid!",
    PASSWORD_REQUIRED = "Password is required!",
    OTP_REQUIRED = "OTP is required!",
    NEW_PASSWORD_REQUIRED = "New password is required!",
    CONFIRM_PASSWORD_REQUIRED = "Confirm password is required!",
    PASSWORDS_DONOT_MATCH = "Two passwords that you entered is inconsistent!",
    PASSWORD_TOO_SHORT = "Password is too short, should have at least 8 characters!",
    CODE_REQUIRED = "Code is required!",
    NAME_REQUIRED = "Name is required!",
    PROMOTION_REQUIRED = "Promotion is required!",
    AMOUNT_REQUIRED = "Amount is required!",
    CURRENCY_REQUIRED = "Currency is required!",
    COUNTRY_REQUIRED = "Country is required!",
    EXPIRY_DATE_REQUIRED = "Expiry date is required!",
    CREDIT_REQUIRED = "Credit ID is required!",
    CURRENT_PASSWORD_REQUIRED = "Current password is required!",
    FIRST_NAME_REQUIRED = "First Name is required!",
    FIRST_NAME_MIN = "First Name requires minimum 2 characters!",
    FIRST_NAME_NOT_VALID = "First Name is not valid!",
    LAST_NAME_REQUIRED = "Last Name is required!",
    LAST_NAME_MIN = "Last Name requires minimum 2 characters!",
    LAST_NAME_NOT_VALID = "Last Name is not valid!",
}

export default ERROR_MESSAGES;