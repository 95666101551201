import fetchAPI from "utils/fetcher";
import API_URLS from "constants/apiUrls";

export async function postExpiryReminder(data: any): Promise<any> {
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(data)
    }

    return await fetchAPI(API_URLS.EXPIRY_REMINDER, options, true);
}


export async function fetchCreditsByPromotion(id: number,searchVal?: string, url?: string): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include',
    }

    return await fetchAPI(url || `${API_URLS.CREDITS_IN_PROMOTION(id)}${searchVal ? "?" + new URLSearchParams({ credit_id: searchVal }) : ""}`, options, true);
}

