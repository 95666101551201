import fetchAPI from "utils/fetcher";
import API_URLS from "constants/apiUrls";

export async function fetchExpiryReminderHistory(params: any): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include'
    }

    return await fetchAPI(`${API_URLS.EXPIRY_REMINDER}?${new URLSearchParams(params)}`, options, true);
}
