export const OTP_RESEND_INTERVAL_S = 30;    // In Seconds
export const FRONTEND_DATE_FORMAT = "DD-MM-YYYY";
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";

export enum PAGE_URLS {
  ERROR_PAGE = "/error/:errorCode",
  ISSUE_CREDITS = "/issue-credits",
  ISSUE_CREDITS_LIST = "/issue-credits/uploaded-data",
  ISSUE_CREDITS_SUCCESS = '/issue-credits/success',
  LOGIN = "/login",
  OTP_VERIFICATION = "/otp-verification",
  RESET_PASSWORD_OTP_VERIFICATION = "/reset-password-otp-verification",
  HEALTH = "/health/",
  RESET_PASSWORD = "/reset-password",
  SELECT_CORPORATE = "/select-corporate",
  FULFILMENT_HISTORY = "/fulfilment-history",
  FULFILMENT_HISTORY_SUCCESS = "/fulfilment-history/successful",
  FULFILMENT_HISTORY_FAILED = "/fulfilment-history/failed",
  FULFILMENT_HISTORY_DETAILED = "/fulfilment-history/detailed",
  SAVED_FILES = "/saved-files",
  USER_MANAGEMENT = "/user-management",
  EXPIRY_REMINDER_HISTORY = "/expiry-reminder-history",
  EXPIRY_REMINDER = "/expiry-reminder-history/send-reminder",
  SETTINGS = "/settings",
  USER_PROFILE = "/settings/user-profile",
  ACCOUNT_SETTINGS = "/settings/account-settings",
  CHANGE_PASSWORD = "/settings/user-profile/change-password",
  PAGE_NOT_FOUND = '/page-not-found',
  NO_PERMISSION = '/error/403',
}


export const REGEX = {
  NUMBER_ONLY: /^[0-9\b]+$/,
}

export enum ISSUE_CREDIT_STATUS {
  COMPLETED = "completed",
  PENDING = "in_progress",
  FAILED = "failed"
}


export enum FULFILMENT_STATUS {
  ALL = "all",
  FAILED = "failed",
  SUCCESS = "completed"
}

export enum OTP_TYPE {
  LOGNI = "login",
  RESET = "reset"
}
