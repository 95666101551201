import {useNavigate } from 'react-router-dom';
import PageHeader from '@features/common/pageHeader/PageHeader'

import "./style.less"
import { PAGE_URLS } from '@constants/common';



export default function Settings() {

  const navigate = useNavigate();

  return (
    <div className="app-container dashboard">
      <PageHeader heading="Settings" />
      <div className="settings-tabs">
        <div className="settings-tabs--card" onClick={() => navigate(PAGE_URLS.USER_PROFILE)}>
          <div className='settings-tabs--icon-wrap blue'>
            <i className="icon icon-user"></i>
          </div>
          <h4>User Profile</h4>
        </div>
      </div>
    </div>
  )
}
