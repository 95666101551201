import React from 'react';
import Layout, { Header, Footer, Content } from 'antd/lib/layout/layout';
import { Card } from 'antd';
import YgagLogo from '../../../images/YgagLogo';
import './style.less';

const ResponsiveWarning: React.FC = () => {
    return (
        <div className='mobile-error-page'>
            <Layout>
                <Header>
                    <YgagLogo height={28} />
                </Header>
                <Content>
                    <Card>
                        <p className='mobile-error-page--info'>Please use your <b>Desktop Web Browser</b> <br /> for a better experience.</p>
                        <img className='mobile-error-page--icon' src={`${process.env.PUBLIC_URL}/images/mobile-warning-graphic.png`} alt="Not supported in this device" />
                    </Card>
                </Content>
                <Footer>
                    Powered By <YgagLogo height={28} />
                </Footer>
            </Layout>
        </div>
    )
}

export default ResponsiveWarning;