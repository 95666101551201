import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { OTP_TYPE, PAGE_URLS } from "constants/common";
import PrivateRoute from './PrivateRoute';
import ErrorPage from 'pages/errorPage/ErrorPage';
import PageLoader from 'features/common/pageLoader/PageLoader';
import ExpiryReminderHistory from '@pages/expiryReminderHistory/ExpiryReminderHistory';
import ExpiryReminder from '@pages/expiryReminder/ExpiryReminder';
import Settings from '@pages/settings/Settings';
const Health = React.lazy(() => import('pages/health/Health'));
const DashboardLayout = React.lazy(() => import('../layout/dashboardLayout/DashboardLayout'));
const Login = React.lazy(() => import('pages/login/Login'));
const OTPVerification = React.lazy(() => import('pages/otpVerification/OTPVerification'));
const ResetPassword = React.lazy(() => import('pages/resetPassword/ResetPassword'));
const SelectCorporate = React.lazy(() => import('pages/selectCorporate/SelectCorporate'));
const IssueCredits = React.lazy(() => import('@pages/issueCredits/IssueCredits'));
const IssueCreditsListing = React.lazy(() => import('@pages/issueCreditsListing/IssueCreditsListing'));
const IssueCreditsSuccess = React.lazy(() => import('@pages/issueCreditsSuccess/IssueCreditsSuccess'));
const FulfilmentHistory = React.lazy(() => import('@pages/fulfilmentHistory/FulfilmentHistory'));
const SavedFiles = React.lazy(() => import('@pages/savedFiles/SavedFiles'));
const UserManagement = React.lazy(() => import('@pages/userManagement/UserManagement'));
const UserProfile = React.lazy(() => import('@pages/userProfile/UserProfile'));
const AccountSettings = React.lazy(() => import('@pages/accountSettings/AccountSettings'));


export default function Router() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          {/* route for devops to verify */}
          <Route path={PAGE_URLS.HEALTH} element={<Health />} />

          <Route element={<PrivateRoute />}>
            <Route path={PAGE_URLS.SELECT_CORPORATE} element={<SelectCorporate />} />
            <Route element={<DashboardLayout />}>
              <Route path={PAGE_URLS.ISSUE_CREDITS} element={<IssueCredits />} />
              <Route path={PAGE_URLS.ISSUE_CREDITS_LIST} element={<IssueCreditsListing />} />
              <Route path={PAGE_URLS.ISSUE_CREDITS_SUCCESS} element={<IssueCreditsSuccess />} />
              <Route path={`${PAGE_URLS.FULFILMENT_HISTORY}/*`} element={<FulfilmentHistory />} />
              <Route path={PAGE_URLS.EXPIRY_REMINDER_HISTORY} element={<ExpiryReminderHistory />} />
              <Route path={PAGE_URLS.EXPIRY_REMINDER} element={<ExpiryReminder/>} />
              <Route path={PAGE_URLS.SAVED_FILES} element={<SavedFiles />} />
              <Route path={PAGE_URLS.USER_MANAGEMENT} element={<UserManagement />} />
              <Route path={PAGE_URLS.SETTINGS} element={<Settings />} />
              <Route path={PAGE_URLS.ACCOUNT_SETTINGS} element={<AccountSettings />} />  
              <Route path={PAGE_URLS.USER_PROFILE} element={<UserProfile />} />
              <Route path={PAGE_URLS.CHANGE_PASSWORD} element={<UserProfile />} />
            </Route>
          </Route>

          <Route path="/" element={<Navigate to={PAGE_URLS.LOGIN} />} />
          <Route path={PAGE_URLS.LOGIN} element={<Login />} />
          <Route path={PAGE_URLS.OTP_VERIFICATION} element={<OTPVerification otpType={OTP_TYPE.LOGNI}/>} />
          <Route path={PAGE_URLS.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={PAGE_URLS.RESET_PASSWORD_OTP_VERIFICATION} element={<OTPVerification otpType={OTP_TYPE.RESET}/>} />
          <Route path={PAGE_URLS.ERROR_PAGE} element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}
