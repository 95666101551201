import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "@redux/store";
import { IErrorMessage, IHelplineNumber, ProfileData } from 'interfaces/common.interface';

export interface CommonSlice {
  profileData: ProfileData;
  isLoading: boolean;
  errorMessage: IErrorMessage,
  isFetchingDashboard: boolean,
  currentCorporate: {
    name: string,
    logo: string
  },
  corporateBalance: {
    amount: number,
    currency: string
  },
  helpline: {
    mobileNumbers: IHelplineNumber[] | null,
    email: string
  }
}

const initialState: CommonSlice = {
  isLoading: false,
  isFetchingDashboard: false,
  currentCorporate: {
    name: "",
    logo: ""
  },
  corporateBalance: {
    amount: 0,
    currency: "AED"
  },
  errorMessage: {
    title: "",
    message: "",
    isShown: false
  },
  helpline: {
    mobileNumbers: null,
    email: ""
  },
  profileData: {
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    corporate: ""
  }
};

// #. Common slice contains the common state methods
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCommonErrorMessage: (state, action: PayloadAction<IErrorMessage>) => {
      state.errorMessage = action.payload;
    },
    setDashboard: (state, action: PayloadAction<any>) => {
      state.corporateBalance = action.payload.corporate_balance;
      state.currentCorporate = {
        logo: action.payload.logo,
        name: action.payload.name,
      }
    },
    setCurrentCorporate: (state, action: PayloadAction<any>) => {
      state.currentCorporate = {
        logo: action.payload.logo,
        name: action.payload.name,
      }
    },
    setIsFetchingDashboard: (state, action: PayloadAction<boolean>) => {
      state.isFetchingDashboard = action.payload;
    },

    setHelplineData: (state, action: PayloadAction<IHelplineNumber[]>) => {
      state.helpline = {
        mobileNumbers: action.payload,
        email: action.payload[0].help_desk_email
      };
    },
    setprofileData: (state, action: PayloadAction<ProfileData>) => {
      state.profileData = action.payload;
    },

    resetCommonSlice: () => initialState
  },
});

export const {
  setLoading,
  setCommonErrorMessage,
  setDashboard,
  setIsFetchingDashboard,
  setCurrentCorporate,
  setHelplineData,
  resetCommonSlice,
  setprofileData,
} = commonSlice.actions;

// #. State for loader component
export const selectIsLoading = (state: AppState) => state.common.isLoading;

// #. Export the reducers
export default commonSlice.reducer;