import { AppState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FulfilmentHistorySlice {
    detailIframeUrl: string
}

const initialState: FulfilmentHistorySlice = {
    detailIframeUrl: ""
};

export const fulfilmentHistorySlice = createSlice({
    name: "fulfilment-history-slice",
    initialState,
    reducers: {
        setFulfilmentDetailUrl: (state, action: PayloadAction<string>) => {
            state.detailIframeUrl = action.payload;
        },

        resetFulfilmentHistorySlice: () => initialState
    },
});

export const selectFulfilmentHistory = (state: AppState) => state.fulfilmentHistory;

export const { setFulfilmentDetailUrl, resetFulfilmentHistorySlice } = fulfilmentHistorySlice.actions;
export default fulfilmentHistorySlice.reducer;