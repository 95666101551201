import React from 'react';
import { Select, SelectProps } from "antd";

import "./style.less"

interface IFloatingSelectProps extends SelectProps {
    label: string,
    value?: string,
    placeholder?: string,
    onPopupScrollEnd?: () => void,
}

const FloatingLabelSelect: React.FC<IFloatingSelectProps> = (props) => {
    const [focus, setFocus] = React.useState(false);
    let { label, value, placeholder, children, onPopupScrollEnd, ...restProps } = props;
    if (!placeholder) placeholder = label;

    const isOccupied = focus || (value && value.length !== 0);
    const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

    const handleScroll = (e: any) => {
        e.persist();
        let target = e.target;
        if ((target.scrollTop + target.offsetHeight === target.scrollHeight) && !props.loading && onPopupScrollEnd) {
            onPopupScrollEnd();
        }
    }

    return (
        <div
            className="floaing-label-select"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >

            <Select
                {...restProps}
                size="large"
                value={value}
                onPopupScroll={handleScroll}
                filterOption={(input, option) => (option?.value?.toString().includes(input) || option?.value === 'loading')}
            >
                {children}
                {props.loading && <Select.Option key='loading' disabled value='loading'>Loading...</Select.Option>}
            </Select>

            <label className={`${labelClass}${props.disabled ? ' disabled' : ''}`}>
                {isOccupied ? label : placeholder}
            </label>
        </div>
    )

}

export default FloatingLabelSelect;
