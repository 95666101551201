import fetchAPI from "utils/fetcher";
import API_URLS from "constants/apiUrls";
import jsonToFormData from "@utils/jsonToFormData";
import { ICreatePromotionForm } from "interfaces/common.interface";

export async function fetchPromotionsList(url?: string): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include'
    }

    return await fetchAPI(url ? url : API_URLS.PROMOTIONS, options, true);
}

export async function postPromotion(data: ICreatePromotionForm): Promise<any> {
    const body = jsonToFormData(data);
    const options = {
        method: 'POST',
        credentials: 'include',
        body
    }

    return await fetchAPI(API_URLS.PROMOTIONS, options, true);
}

export async function postExcelFile(data: any): Promise<any> {
    const body = jsonToFormData(data);
    const options = {
        method: 'POST',
        credentials: 'include',
        body,
    }

    return await fetchAPI(API_URLS.EXCEL_UPLOAD, options, true);
}


export async function getLatestUploadedFile(): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include'
    }

    return await fetchAPI(API_URLS.DOWNLOAD_LATEST_FILE, options, true);
}


export async function getSampleFile(): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include',
    }

    return await fetchAPI(API_URLS.DOWNLOAD_SAMPLE_FILE, options, true);
}