import React from 'react'
import { Layout } from 'antd';
import { useAppSelector } from 'redux/store';
import Footer from 'features/common/footer/Footer';
import UserPopoverMenu from 'features/common/userPopoverMenu/UserPopoverMenu';
import YgagLogo from 'images/YgagLogo'

import './style.less'

const { Content } = Layout;

interface LayoutProps {
    heading?: String;
    children: React.ReactElement;
    alignCenter?: boolean
}

const PublicLayout: React.FC<LayoutProps> = ({ heading, children, alignCenter }) => {

    const { isLoggedIn } = useAppSelector(state => state.auth)
    return (
        <Layout className='public-layout'>
            <Layout.Header>
                {heading ?
                    <h1>{heading}</h1>
                    :
                    <div className="public-layout--logo-container">
                        <YgagLogo />
                    </div>
                }
                {isLoggedIn &&
                    <div className='public-layout--user-menu'>
                        <UserPopoverMenu />
                    </div>
                }
            </Layout.Header>
            <Content className={alignCenter ? "align-center" : ""}>
                {children}
            </Content>
            <Footer />
        </Layout>
    )
}

export default PublicLayout;