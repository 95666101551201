import PageHeader from '@features/common/pageHeader/PageHeader';
import ExpiryReminderHistoryTable from '@features/expiryReminderHistory/expiryReminderHistoryTable/ExpiryReminderHistoryTable';
import './style.less';


export default function ExpiryReminderHistory() {


  return (
    <div className="app-container dashboard">
      <PageHeader heading ="Expiry Reminder History" />
      <div className='expiry-reminder-history'>
        <ExpiryReminderHistoryTable />
      </div>
    </div>
  )
}
