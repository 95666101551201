import React from 'react'
import { Button, Col, Form, message, Row, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import FloatingLabelSelect from '@features/common/floatingLabelSelect/FloatingLabelSelect';
import { fetchPromotionsList } from '@features/issueCredits/issueCreditsAPI';
import { IPromtionData } from 'interfaces/common.interface';
import './style.less'
import ERROR_MESSAGES from '@constants/errorMessages';
import { fetchCreditsByPromotion, postExpiryReminder } from '../expiryReminderAPI';
import { PAGE_URLS } from '@constants/common';

interface IReminderForm {
    credit_id: number,
    promotion: number
}

interface ICreditsData {
    customer_username: string,
    expiration_date: string,
    id: number,
    reference_id: string
}

const CreateReminderForm = () => {

    const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

    const [isFetchingPromotions, setFetchingPromtions] = React.useState<boolean>(false);
    const [promotionsNextPage, setPromotionsNextPage] = React.useState<string>("");
    const [promotions, setPromotions] = React.useState<Array<IPromtionData>>([]);

    const [isFetchingCredits, setFetchingCredits] = React.useState<boolean>(false);
    const [creditsNextPage, setCreditsNextPage] = React.useState<string>("");
    const [credits, setCredits] = React.useState<Array<ICreditsData>>([]);

    const navigate = useNavigate();

    const [form] = Form.useForm<IReminderForm>();
    const promotionVal = Form.useWatch('promotion', form);

    React.useEffect(() => {
        getPromotionsList();
    }, [])


    const getPromotionsList = async (url?: string) => {
        setFetchingPromtions(true);
        const res = await fetchPromotionsList(url);
        if (res.ok) {
            setPromotions((prevValues) => [...prevValues, ...res.data.data]);
            setPromotionsNextPage(res.data.next)
        }

        setFetchingPromtions(false);
    }

    const getCreditsList = async (chosenPromotion: number, searchVal?: string, url?: string) => {
        setFetchingCredits(true);
        const res = await fetchCreditsByPromotion(chosenPromotion, searchVal, url);
        if (res.ok) {
            setCredits((prevValues) => [...prevValues, ...res.data.data]);
            setCreditsNextPage(res.data.next);
        }

        setFetchingCredits(false);
    }


    const debouncedCreditsSearch = debounce((searchVal: string) => {
        setCredits([]);
        getCreditsList(promotionVal, searchVal)
    }, 500);

    const handlePromotionChange = async (val: number) => {
        form.setFieldValue('credit_id', null);
        if (val !== promotionVal) {
            setCredits([]);
            getCreditsList(val);
        }
    }

    const handleSubmit = async (values: IReminderForm) => {
        setSubmitting(true);
        const res = await postExpiryReminder(values);

        if (res.ok) {
            message.success(res.data.message || "Credit expiry reminder sent successfully");
            navigate(PAGE_URLS.EXPIRY_REMINDER_HISTORY)
        } else {
            message.error(res.data.message || "Failed to send credit expiry reminder");
        }
        setSubmitting(false);
    }
    return (
        <Form form={form} size='large' className='create-reminder-form' onFinish={handleSubmit}>
            <Row gutter={32}>
                <Col span={12}>
                    <Form.Item className='mb-0' name="promotion" rules={[{ required: true, message: ERROR_MESSAGES.PROMOTION_REQUIRED }]} >
                        <FloatingLabelSelect
                            label="Promotion Name"
                            loading={isFetchingPromotions}
                            onChange={handlePromotionChange}
                            onPopupScrollEnd={() => promotionsNextPage && getPromotionsList(promotionsNextPage)}
                        >
                            {promotions.map((promotion) => (
                                <Select.Option key={promotion.id} value={promotion.id}>{promotion.name}</Select.Option>
                            ))}
                        </FloatingLabelSelect>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className='mb-0'
                        name="credit_id"
                        rules={[{ required: true, message: ERROR_MESSAGES.CREDIT_REQUIRED }]}
                    >
                        <FloatingLabelSelect
                            label="Credit ID"
                            loading={isFetchingCredits}
                            disabled={!promotionVal}
                            onPopupScrollEnd={() => creditsNextPage && getCreditsList(0, "", creditsNextPage)}
                            showSearch
                            onSearch={debouncedCreditsSearch}
                        >
                            {credits.map((credit) => (
                                <Select.Option key={credit.id} value={credit.id}>{credit.id}</Select.Option>
                            ))}
                        </FloatingLabelSelect>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end' className='create-reminder-form--bottom'>
                <Col>
                    <Button loading={isSubmitting} htmlType='submit' size='large' type='primary'>Send a Reminder</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default CreateReminderForm;