const isUnsupporteddevice = () => {
    const deviceAgent = navigator.userAgent.toLowerCase();

    return window.screen.width < 1024 &&
        (deviceAgent.match(/android/i) ||
            deviceAgent.match(/webos/i) ||
            deviceAgent.match(/(iphone|ipod|ipad)/i) ||
            deviceAgent.match(/blackberry/i))
}

export default isUnsupporteddevice;