import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExcelSummaryData } from "interfaces/common.interface";

export interface IssueCreditsListingSlice {
    summaryData: IExcelSummaryData | null,
    isFetchingSummary: boolean
}

const initialState: IssueCreditsListingSlice = {
    summaryData: null,
    isFetchingSummary: false
};

export const issueCredistListingSlice = createSlice({
    name: "issue-credits-listing",
    initialState,
    reducers: {
        setSummaryData: (state, action: PayloadAction<IExcelSummaryData>) => {
            state.summaryData = action.payload;
        },
        setIsFetchingSummary: (state, action: PayloadAction<boolean>) => {
            state.isFetchingSummary = action.payload;
        },
    },
});

export const { setSummaryData, setIsFetchingSummary } = issueCredistListingSlice.actions;
export default issueCredistListingSlice.reducer;