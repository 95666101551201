import fetchAPI from "utils/fetcher";
import API_URLS from "constants/apiUrls";

export async function fetchHelpLine(): Promise<any> {
    const options = {
        method: 'GET',
    }

    return await fetchAPI(API_URLS.HELPLINE, options);
}

export async function fetchProfile(): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include'
    }

    return await fetchAPI(API_URLS.USER_PROFILE, options, true);
}

export async function fetchDashboard(): Promise<any> {
    const options = {
        method: 'GET',
        credentials: 'include'
    }

    return await fetchAPI(API_URLS.DASHBOARD, options, true);
}