import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import commonReducer from "features/common/commonSlice";
import authReducer from "features/common/authSlice";
import issueCreditsListingReducer from "features/issueCreditsListing/issueCreditsListingSlice";
import fulfilmentHistoryReducer from "features/fulfilmentHistory/fulfilmentHistorySlice";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
};

const authPersistConfig = {
    key: 'auth',
    storage: storageSession,
    whitelist: ["isResendOTPEnabled", "isLoggedIn"]
}

const rootReducer = combineReducers({
    common: commonReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    creditsListing: issueCreditsListingReducer,
    fulfilmentHistory: fulfilmentHistoryReducer
});

export default persistReducer(persistConfig, rootReducer);
