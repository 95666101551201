import React from 'react';
import { useAppSelector } from '@redux/store';
import numberWithCommas from '@utils/numberWithComas';
import "./style.less";

interface PageHeaderProps {
    heading: string,
    children?: JSX.Element
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const { corporateBalance, isFetchingDashboard } = useAppSelector(state => state.common);

    return (
        <div className='page-header'>
            <div>
                <h1>{props.heading}</h1>
                <div className='page-header--children'>
                    {props.children}
                </div>
            </div>


            {!isFetchingDashboard &&
                <div className="page-header--account-balance-section">
                    <img className='page-header--account-balance-icon' src={`${process.env.PUBLIC_URL}/images/balance-icon.png`} alt="Balance Icon" />
                    <div className="page-header--account-balance-details">
                        <label>Available Balance</label>
                        <h4>{`${corporateBalance.currency} ${numberWithCommas(corporateBalance.amount)}`}</h4>
                    </div>
                </div>
            }
        </div>
    )
}

export default PageHeader;